import { i18trans } from '~/translation/i18n';
import { type TInvoiceType } from '~/domain/invoices/types/invoiceType';

type TTypeTag = Record<TInvoiceType, { title: string; color: string }>;

export const invoiceTypesTags: TTypeTag = {
  platformfee: {
    title: i18trans.t('invoiceTypes.platformfee', { ns: 'invoice' }),
    color: '#01AFF1',
  },
  eordeposit: {
    title: i18trans.t('invoiceTypes.eordeposit', { ns: 'invoice' }),
    color: '#01AFF1',
  },
  fixedrate: {
    title: i18trans.t('invoiceTypes.fixedrate', { ns: 'invoice' }),
    color: '#01AFF1',
  },
  eor: {
    title: i18trans.t('invoiceTypes.eor', { ns: 'invoice' }),
    color: '#01AFF1',
  },
  paygo: {
    title: i18trans.t('invoiceTypes.paygo', { ns: 'invoice' }),
    color: '#01AFF1',
  },
};
